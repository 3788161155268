:root {
  color-scheme: light dark;
}

body {
  /* margin: 0; */
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;*/
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.stack > * + * {
  margin-top: 1rem;
}

.note {
  --light: 0%;
  display: inline-block;
  padding: 0.5em;
  border: 1px solid hsla(0, 0%, var(--light, 0%), 0.2);
  border-radius: 4px;
  background-color: hsla(0, 0%, var(--light, 0%), 0.1);
}

.category {
  --light: 0%;
  color: hsla(0, 0%, var(--light, 0%), 0.5);
}

.typography.muted {
  --light: 0%;
  color: hsla(0, 0%, var(--light, 0%), 0.5);
}

@media (prefers-color-scheme: dark) {
  .note {
    --light: 100%;
  }

  .category {
    --light: 100%;
  }

  .typography.muted {
    --light: 100%;
  }
}
